import React from "react";
import crisis from "./images/crisis.jpg";
import crisis1 from "./images/crisis1.png";
import { Link } from "react-router-dom";
import "./Crisis.css";
import { useEffect } from "react";

function Crisis() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  }, []);
  return (
    <div className="crisis-container">
      <div
        className="news-banner-area"
        style={{ background: `url(${crisis})` }}
      >
        <div className="news-content container">
          <div className="news-links">
            <h2 className="news-h2">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>
                <Link to="/news" className="home-link">
                  Blog
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
            </ul>
            <p>
              {" "}
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </p>
          </div>
        </div>
      </div>

      <div className="news-content-container container">
        <div className="news-left-content">
          <img src={crisis1} className="img-crisis" alt="" />
          <p className="pricing-para">
            <strong>Posted On:</strong> March 20, 2020{" "}
            <strong>Posted By:</strong> Nayeem Ekbal
          </p>
          <p className="pricing-para">
            Civil aviation has become a major industry in our time. Without air
            travel, mass international tourism would not exist, nor could global
            supply chains function. Some 40 % of high-tech sales depend on good
            quality air transport, and there is no alternative mode of transport
            for perishable commodities such as fresh food or cut flowers.
          </p>
          <p className="pricing-para">
            Demand for aviation is characterized by constant fluctuation,
            procyclicality, seasonality, directional flow and perishability.
            Factors affecting air traffic include Gross Domestic Product (GDP),
            population growth, political stability, amount of leisure time and
            market access.
          </p>
          <p className="pricing-para">
            The civil aviation industry has been ridden with crises since 2000,
            some of them man-made, others caused by nature.
          </p>
          <p className="pricing-para">
            <strong>1. The Terrorist Attack 9/11:</strong> 11 September 2001,
            the terrorist attacks of 9/11 had a devastating effect on air
            travel. US airspace was closed for a week and international travel
            was severely affected. Available seat kilometers for winter 2001–02
            were down by 15 % on Transatlantic and Trans-Pacific routes.
          </p>
          <p className="pricing-para">
            <strong>2. SARS 2002–03</strong> It is claimed that the Severe Acute
            Respiratory Syndrome (SARS) epidemic (November 2002–July 2003)
            caused more damage to air transport than 9/11 and the Iraq war put
            together. Traffic in April 2003 was down 18.5 % year on year.
            Especially hard hit were airlines in Asia and airports in Hong Kong
            (China), Singapore and Seoul, where traffic decreased 40–60 %.
          </p>
          <p className="pricing-para">
            <strong>3. Economic crisis 2008–09</strong> As a pro-cyclical
            industry, civil aviation suffered major losses during the economic
            crisis of 2008–09. In 2008 operating losses for the 150 biggest
            airlines were US$15 billion and in the United States 13 airlines
            went bankrupt. In Europe, Scandinavian Airlines (SAS) was the one
            reducing most capacity, by 40 %. The economic crisis resulted in
            varied outcomes for airline personnel.
          </p>
          <p className="pricing-para">
            <strong>4. Volcanic ash problems in 2010</strong> The eruption of a
            volcano in Iceland, in April 2010, caused the largest breakdown in
            civil aviation since Second World War. During the first week 100,000
            flights were cancelled and the costs for the global economy during
            that week were calculated as US$4.7 billion. The disruption affected
            some 10 million passengers.
          </p>
          <p className="pricing-para">
            <strong>5. Coronavirus 2020:</strong> The impact of the coronavirus
            could result in passenger airlines losing up to $113bn (£87bn) in
            revenues this year, the International Air Transport Association
            (IATA) has said. The potential $113 billion passenger revenue hit is
            financially on par with what the industry experienced in 2009,
            during the global financial crisis.
          </p>
          <p className="pricing-para">
            However, since 1970 the number of flights has tripled and the number
            of passengers grown five-fold, Currently the forecasts indicate that
            air traffic volumes will more than double in the next 20 years. The
            industries directly supported by aviation will grow in tandem,
            increasing business-to-business and consumer benefits and choices,
            improving efficiency, spurring job creation, and sparking local and
            national development.
          </p>
          <p className="pricing-para">
            Aviation’s global stature as an economic engine is evident. If the
            global aviation sector were a country, its total contribution
            (direct, indirect, induced, and catalytic) of USD 2.7 trillion to
            the gross domestic product (GDP), and the 65.5 million jobs it
            supports, would be comparable to the United Kingdom’s economic size
            and population.
          </p>
          <p className="pricing-para">
            Aviation has continued to expand. It has weathered crises and
            demonstrated long-term resilience, becoming an indispensable means
            of transport. Historically, air transport has doubled in size every
            fifteen years and has grown faster than most other industries. In
            2018, airlines worldwide carried around 4.3 billion passengers
            annually with 8.3 trillion revenue passenger kilometers (RPKs).
            Fifty-eight million tons of freight were transported by air,
            reaching 231 billion freight ton kilometers (FTKs). Every day, more
            than 100,000 flights transport almost 12 million passengers and
            around USD 18 billion worth of goods.
          </p>
          <p className="pricing-para">
            Silver Lining: With change as the only constant, the aviation
            industry will make changes, accordingly, ensuring that they can cope
            with any situations faced. Many of the disasters caught the aviation
            community by surprise. However, the aviation community was able to
            react quickly to the crises as suitable contingencies and strategies
            in place knowing that the passenger demand will bounce back.
            Airlines always preparing for the next possible kind of crisis
            because nothing ever stays the same. The aviation community have
            learnt valuable lessons from the recent aviation-related crises:
            They not only gave us an indication of how a serious pandemic, or a
            natural disaster can quickly create a crisis, but also showed us how
            important it is to identify potential crises, how it’s going to
            affect us and how we’re going to respond and manage these crises
            accordingly. They are mindful that while it is difficult to expect
            the unexpected, they continue to prepare themselves as best as
            possible through a series of crisis management plans and exercises
            that hone their reflexes and ensure they can minimize the fallout
            from any unpreventable emergency.
          </p>
          <p className="pricing-para">
            The ReveMax Solution is part of the silver lining where it can
            optimize and maximize the revenue of the airline with the existing
            and forthcoming demand. This one stop shop with a 360-degree
            solution brings in efficiency and transparency to the Commercial
            process as to bring clarity in doing business. Airlines need to use
            this time at hand to make their internal and external processes
            robust as to be prepared for the burst of demand which will be
            coming their way, once this short-term crisis is under control.
          </p>
          <div className="references-container">
            <p>
              <strong>References</strong>
            </p>
            <p>
              <strong>
                1.&nbsp;&nbsp;&nbsp;&nbsp;Aviation Benefits Report
                2019
              </strong>
            </p>
            <p>
              <strong>
                2.&nbsp;&nbsp;&nbsp;&nbsp;GDFCAI/2013 –
                International Labor Organization
              </strong>
            </p>
            <p>
              <strong>3.&nbsp;&nbsp;&nbsp;&nbsp;</strong><br/>
              <a href="https://www.theguardian.com/business/2020/mar/05/airlines-could-lose-up-to-113bn-on-back-of-coronavirus-says-iata">
                <strong>
                  https://www.theguardian.com/business<br></br>/2020/mar/05/airlines-could-lose-up-to-113bn-
                  on-back-of-coronavirus-says-iata
                </strong>
              </a>
            </p>
            <p>
              <strong>4.&nbsp;&nbsp;&nbsp;&nbsp;</strong><br></br>
              <a href="https://www.flightglobal.com/airlines/iata-flags-up-to-113-billion-revenue-hit-from-coronavirus-crisis/137101.article">
                <strong>
                  https://www.flightglobal.com/airlines/iata-flags-up-to-113-billion-revenue-hit-from-coronavirus-crisis/137101.article
                </strong>
              </a>
            </p>
          </div>
          <div className="comment-form-container">
            <p>
              <strong>Leave a Reply</strong>
            </p>
            <p>
              Your email address will not be published. Required fields are
              marked *
            </p>
            <form
              className="comment-form"
              action="https://revemax.ai/wp-comments-post.php"
              method="post"
              id="commentform"
              class="comment-form"
              novalidate=""
            >
              <div className="comment-section">
                <label htmlFor="comment">Comment</label>
                <textarea
                  className="textarea"
                  rows="5"
                  cols="40"
                  required
                ></textarea>
              </div>
              <div className="name-email">
                <div className="name-section">
                  <label htmlFor="name">Name *</label>
                  <input type="text" name="name" required />
                </div>
                <div className="email-section">
                  <label htmlFor="email">Email *</label>
                  <input type="email" name="email" required />
                </div>
              </div>
              <div className="website-section">
                <label htmlFor="website">Website</label>
                <input type="text" name="website" required />
              </div>
              <div className="checkbox-container">
                <input type="checkbox" id="check" />
                <p>
                  Save my name, email, and website in this browser for the next
                  time I comment
                </p>
              </div>
              <div className="post-btn">
                <input
                  type="SUBMIT"
                  className="comment-btn"
                  value="POST A COMMENT"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="news-right-content">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search here..."
              className="search-input"
            />
            <i class="fa fa-search"></i>
          </div>
          <div className="annoucements">
            <h2 className="widget-title">Annoucements</h2>
            <Link to="/malaysiaairlines" className="annoucement-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
          </div>
          <div className="recent-posts">
            <h2 className="widget-title">Recent Posts</h2>
            <Link to="/malaysiaairlines" className="news-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
            <Link to="/pricing" className="news-link">
              RETHINKING THE PRICING DYNAMIC
            </Link>
            <Link to="/economics" className="news-link">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </Link>
            <Link to="/crisis" className="news-link">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </Link>
          </div>
          <div className="recent-comments">
            <h2 className="widget-title">Recent Comments</h2>
          </div>
          <div className="categories">
            <h2 className="widget-title">Categories</h2>
            <Link to="/uncategorized" className="news-link">
              Uncategorized
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Crisis;
