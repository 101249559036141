import {React,useEffect} from "react";
import { Link } from "react-router-dom";
import admin from "./images/admin.jpg";

function Admin() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="news-container">
      <div className="news-banner-area" style={{ background: `url(${admin})` }}>
        <div className="news-content">
          <div className="news-links">
            <h2 className="news-h2">Author: admin</h2>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="news-content-container">
        <div className="news-left-content">
          <div className="content-news-container">
            <p className="article-para">Admin / June 05, 2021 / No Comments</p>
            <Link to="/malaysiaairlines" className="article-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
            <p className="article-para">
              KLIA, 17 May 2021: Malaysia Airlines is pleased to announce its
              partnership with ReveMax, a leading provider of Digital Strategic
              Solutions, to implement the Airline Revenue Maximization Solution
              (ARMS) which will enhance and strengthen the airline’s commercial
              decision-making and business processes.
            </p>
            <a href="" className="read-btn">
              Read More <i class="fa fa-plus"></i>
            </a>
          </div>
        </div>
        <div className="news-right-content">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search here..."
              className="search-input"
            />
            <i class="fa fa-search"></i>
          </div>
          <div className="annoucements">
            <h2 className="widget-title">Annoucements</h2>
            <Link to="/malaysiaairlines" className="annoucement-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
          </div>
          <div className="recent-posts">
            <h2 className="widget-title">Recent Posts</h2>

            <Link to="/malaysiaairlines" className="news-link">
              Malaysia Airlines Partners with ReveMAX to Redefine its Revenue
              Strategy.
            </Link>
            <Link to="/pricing" className="news-link">
              RETHINKING THE PRICING DYNAMIC
            </Link>
            <Link to="/economics" className="news-link">
              AIRLINE ECONOMICS DURING THE TRANSITION TO A POST-PANDEMIC WORLD
            </Link>
            <Link to="/crisis" className="news-link">
              CRISIS AT THE SUMMIT – AVIATION’S SILVER LINING WITH A LIGHT AT
              THE END OF THE TUNNEL
            </Link>
          </div>
          <div className="recent-comments">
            <h2 className="widget-title">Recent Comments</h2>
          </div>
          <div className="categories">
            <h2 className="widget-title">Categories</h2>
            <Link to="/uncategorized" className="news-link">
              Uncategorized
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
