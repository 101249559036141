import React from "react";
import { Link } from "react-router-dom";
import "./Baggage.css"
import baggage from "../../images/baggage.jpg";
import {useEffect} from "react";

function Baggage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="baggage-container">
      <div
        className="baggage-banner-area"
        style={{ background: `url(${baggage})` }}
      >
        <div className="baggage-content container">
          <div className="baggage-links">
            <h2 className="baggage-h2">Xtra baggage</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Xtra baggage</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="baggage-content container">
        <div className="baggage-heading">
        <i className="fa fa-briefcase"></i>
          <h2 className="baggage-title">Xtra baggage</h2>
        </div>
        <p className="baggage-para">
          The solution earns revenues from selling baggage options that can be
          optimized on the bundling concept of the different ancillary products
          with built in airline strategy. The solution can assist airline to
          price the excess baggage based on cargo, total value of the customer
          value and potential network revenue based on the journey details.
        </p>
      </div>
    </div>
  );
}

export default Baggage;
