import React from "react";
import video from "./images/video.jpeg";
import "./Video.css";
import { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";

function Video() {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="video container">
      <div className="video-text-container">
        <h1>Check out what ReveMax can do</h1>
        <p>
          As a <span id="startergy">#StrategicDecisionSupportPlatform </span>
          (SDSP) ReveMax can effectively and systematically bring sanity to your
          commercial airline operations. Our (ARMS) Platform can give your
          executives and support teams unprecedented clarity on the overall
          financial health of your airlines. Bringing your Airline Strategy into
          the Digital World
        </p>
      </div>
      <div className="video-img-container">
        <img src={video} alt="video" className="video-img" />
      </div>
      <div className="play">
        {/* <a className="popup-youtube" href="https://www.youtube.com/watch?v=OklH5jU42Ls" target="_blank">
          <i className="fa fa-play"></i>
        </a> */}
        <a onClick={() => setOpen(true)}>
          <i className="fa fa-play"></i>
        </a>
      </div>
      <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="OklH5jU42Ls"
          onClose={() => setOpen(false)}
        />
      
    </div>
  );
}
// onClick={() => setOpen(true)}
export default Video;
