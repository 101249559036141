import React from "react";
import { Link } from "react-router-dom";
import "./Lounge.css";
import lounge from "../../images/lounge.jpg";
import {useEffect} from "react";

function Lounge() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="lounge-container">
      <div
        className="lounge-banner-area"
        style={{ background: `url(${lounge})` }}
      >
        <div className="lounge-content container">
          <div className="lounge-links">
            <h2 className="lounge-h2">Lounge option</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Lounge option</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="lounge-content container">
        <div className="lounge-heading">
        <i className="fa fa-building"></i>
          <h2 className="lounge-title">Lounge option</h2>
        </div>
        <p className="lounge-para">
          The solution earns revenues for airlines from selling Lounge access
          based on availability. This will be linked to the airline strategy,
          customer profile, other trip related factors etc. as not to dilute the
          Lounge product offering
        </p>
      </div>
    </div>
  );
}

export default Lounge;
