import {React,useEffect} from "react";
import { Link } from "react-router-dom";
import "./Ancillary.css";
import ancillary from "../../images/ancillary.jpg";

function Ancillary() {
  useEffect(() => {

    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])

  return (
    <div className="ancillary-container">
      <div
        className="ancillary-banner-area"
        style={{ background: `url(${ancillary})` }}
      >
        <div className="ancillary-content container">
          <div className="ancillary-links">
            <h2 className="ancillary-h2">
              Other ancillary products integration
            </h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Other ancillary products integration</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="ancillary-content container">
        <div className="ancillary-heading">
          <i className="fa fa-plus"></i>
          <h2 className="ancillary-title">
            Other ancillary products integration
          </h2>
        </div>
        <p className="ancillary-para">
          The solution has the capability to integrate any ancillary products
          which exist in the airline ecosystem. ReveMAX gives a single platform
          or single stop shop concept powered by intelligent technologies which
          optimize the product offering so that the airlines can maximize their
          increment revenue. All this can be linked to the ReveMax solution with
          a Forecasting capability to implement appropriate strategies.
        </p>
      </div>
    </div>
  );
}

export default Ancillary;
