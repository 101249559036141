import { React, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import image1 from "./images/New-Project-18.png";
import image2 from "./images/New-Project-17.png";
import {useEffect} from "react";

function NavbarMenu(onClick) {
  const [navbar, setNavbar] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  const changeBackground = () => {
    if (window.scrollY >= 180) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);


  function handleClick(){
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";
  }
  return (
    <>
      <nav className={navbar ? "navbarMenu active" : "navbarMenu"}>
      <div className="container navbarContainer">
      <div className="logo-container">
          <Link to="/"><img src={navbar ? image2 : image1} alt="logo" className="logo" /></Link>
        </div>
        <div className={navbar ? "openMenu active" : "openMenu"}><i className="fa fa-bars"></i></div>
        <div className={navbar ? "navbar-list active" : "navbar-list"}>
          <ul>
            <li>
              <Link to="/" onClick={handleClick} className="link" id="homeLink">Home</Link>
            </li>
            <li>
              <Link to="/about" onClick={handleClick} className="link" id="aboutLink">About</Link>
            </li>
            <li className={navbar ? "product active" : "product"}>
              Products <i class="fa fa-caret-down"></i>
              <div className="product-links">
                <div className="pro-link">
                  <Link className="product-link" id="armsLink" to="/ARMS">
                    ARMS
                  </Link>
                </div>
                {/* <div className="pro-link">
                  <Link className="product-link" id="arxLink" to="/ARX">
                    ARX
                  </Link>
                </div> */}
                <div className="pro-link">
                  <Link className="product-link" id="consultingLink" to="/Consulting">
                    Consulting
                  </Link>
                </div>
              </div>
            </li>
            <li>
              <Link to="/news" className="link" id="newsLink">News</Link>
            </li>
            <li>
              <Link to="/contact" className="link" id="contactLink">Contact</Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={navbar ? "connect-us active" : "connect-us"}
              >
                Connect with Us
              </Link>
            </li>
            <div className={navbar ? "closeMenu active" : "closeMenu"}><i className="fa fa-times"></i></div>
          </ul>
        </div>

        {/* <div className={navbar ? "nav-icon active" : "nav-icon"}>
          <div></div>
        </div> */}
      </div>
      </nav>
    </>
  );
}

export default NavbarMenu;
