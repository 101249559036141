import React from "react";
import logo from "./images/New-Project-18.png";
import map from "./images/map.png";
import "./Footer.css";
import { Link } from "react-router-dom";
import {useEffect} from "react";

function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])
  return (
    <>
      <div className="footer-container">
       <div className="container footer-container">
       <div className="footer-info">
          <img src={logo} alt="" />
          <p>
            REVEMAX is technology-driven, industry-focused, and future proof.
            Our goal is to help the commercial airline industry to monitor and
            manage its performance trends through calculated decisions. CANADA -
            USA - INDIA - TURKEY
          </p>
          <div className="social-icons">
            <div className="icon">
              <a href="#">
                <i class="fa fa-facebook"></i>
              </a>
            </div>
            <div className="icon">
              <a href="#">
                <i class="fa fa-twitter"></i>
              </a>
            </div>
            <div className="icon">
              <a href="#">
                <i class="fa fa-instagram"></i>
              </a>
            </div>
            <div className="icon">
              <a href="#">
                <i class="fa fa-youtube-play"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="footer-quick-links">
          <div className="footer-heading">
            <h4>Quick Links</h4>
          </div>
          <div className="quick-links">
            <ul>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/news">News</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-product">
          <div className="footer-heading">
            <h4>Product</h4>
          </div>
          <div className="footer-product-links">
            <ul>
              <li>
                <Link to="/arx" className="foo-pro-links">ARX</Link>
              </li>
              <li>
                <Link to="/arms" className="foo-pro-links">ARMS</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="circle-map">
          <img src={map} alt="" />
        </div>
        {/* <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div> */}
       </div>
      </div>
     
      <div className="copyright-container">
      <div className="container">
        <p>
          Copyright 2021 <Link to="/">ReveMax</Link>. All Rights
          Reserved.{" "}
        </p>
        </div>
        </div>
      <div className="container">
      <div className="go-top active">
        <i className="fa fa-arrow-up"></i>
        <i className="fa fa-arrow-up"></i>
      </div>
      </div>
     
  
    </>
  );
}

export default Footer;
