import React from "react";
import { Link } from "react-router-dom";
import "./ARX.css";
import arx from "../images/arx.png";
import {useEffect} from "react";

function ARX() {
  useEffect(() => {
    window.scrollTo(0, 0)

    const preloader = document.querySelector('.preloader');
    preloader.className = 'preloader preloader-deactivate';

    return () => { preloader.className = 'preloader'; }
  },[])
  return (
    <div className="arx-container">
      <div className="arx-banner-area" style={{ background: `url(${arx})` }}>
        <div className="arx-content container">
          <div className="arx-links">
            <h2 className="arx-h2">ARX</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>ARX</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>
      <div className="arx-para container">
        <p>
          ReveMax ARX a white-label Ancillary solution is geared by a set of
          features and functionalities coupled with a built-in airline strategy,
          offering a dynamic solution which can evolve with the Airlines growth
          and requirements. We believe in Business driving the solution rather
          than the Solution driving the business hence it can be tailor-made to
          the needs of the Airline to capitalize and reflect their brand through
          one voice along the solution’s experience across its landscape.
        </p>
        <p>
          By incorporating intelligent targeting and a robust decision-making
          engine, the solution provides high yield incremental ancillary revenue
          for airlines. The solution brings in sophisticated algorithm engines
          focused on Customer Relationship Management with a seamless customer
          satisfaction journey using our suite of airline ancillary products.
        </p>
      </div>
      <div className="arx-content-container container">
        <div className="arx-contents">
          <i className="fa fa-arrow-up"></i>
          <Link to="/upgrade" className="arx-content-links">
            <h3 className="arx-heading">Upgrade Option</h3>
            <p className="arx-para-btn">Read More</p>
          </Link>
        </div>
        <div className="arx-contents">
          <i className="fa fa-briefcase"></i>
          <Link to="/baggage" className="arx-content-links">
            <h3 className="arx-heading">Xtra baggage</h3>
            <p className="arx-para-btn">Read More</p>
          </Link>
        </div>
        <div className="arx-contents">
          <i className="fa fa-building"></i>
          <Link to="/lounge" className="arx-content-links">
            <h3 className="arx-heading">Lounge option</h3>
            <p className="arx-para-btn">Read More</p>
          </Link>
        </div>

        <div className="arx-contents">
          <i class="fa fa-scissors"></i>
          <Link to="/emptyseat" className="arx-content-links">
            <h3 className="arx-heading">Empty seat option</h3>
            <p className="arx-para-btn">Read More</p>
          </Link>
        </div>
        <div className="arx-contents">
          <i class="fa fa-thumbs-up"></i>
          <Link to="/preferredseat" className="arx-content-links">
            <h3 className="arx-heading">Preferred seat option</h3>
            <p className="arx-para-btn">Read More</p>
          </Link>
        </div>
        <div className="arx-contents">
          <i className="fa fa-plus"></i>
          <Link to="/ancillary" className="arx-content-links">
            <h3 className="arx-heading">Other ancillary products integration</h3>
            <p className="arx-para-btn">Read More</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ARX;
