import {React, useRef} from "react";
import { Link } from "react-router-dom";
import contactUs from "./images/contact-us.jpg";
import contact from "./images/contact.png";
import "./Contact.css";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

function Contact() {
const captcha = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader preloader-deactivate";
    };
  }, []);
  const [captchaValid, setCaptchaValid] = useState(null);
  const [userValid, setUserValid] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [checkemail, setCheckemail] = useState(false);
  const [emailNotSend, setEmailnotSend] = useState(false);

  
  function onChange(){
    if(captcha.current.getValue()){

    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    

    const text =
      "Name:" +
      name +
      "\n" +
      "Email:" +
      email +
      "\n" +
      "Phone:" +
      phone +
      "\n" +
      "message:\n" +
      message;
    const data = { info: text, subj: subject };

    axios
      .post("https://revemax.ai/api/send_form_data/", data)
      .then(() => {
        setCheckemail(true);
      })
      .catch((e) => {
        setEmailnotSend(true);
        console.log("message not sending properly", e);
      });

      if(captcha.current.getValue()){
        setUserValid(true);
        setCaptchaValid(true);
      }else{
        setUserValid(false);
        setCaptchaValid(false);
      }
    setName("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
  };

  if (checkemail || emailNotSend) {
    setTimeout(changeemail, 3000);
  }

  if(captchaValid === false){
    setTimeout(changeCaptcha, 3000);
  }

  function changeCaptcha() {
    if (captchaValid === false) setCaptchaValid(true);
  }

  function changeemail() {
    if (checkemail) setCheckemail(false);
    else setEmailnotSend(false);
  }
  // console.log(checkemail);

  return (
    <div className="contact-container">
      <div
        className="contact-banner-area"
        style={{ background: `url(${contactUs})` }}
      >
        <div className="contact-content container">
          <div className="contact-links">
            <h2 className="contact-h2">Contact</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Contact</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="contact-form-container container">
        <div className="contact-heading-container">
          <p className="contact-para">CONTACT US</p>
          <h1 className="contact-heading">Drop us a note</h1>
        </div>
        <div className="contact-form-content">
          <div className="contact-form-img">
            <img src={contact} alt="" className="contact-image" />
          </div>
         
          <div className="contact-form">
           
            <form onSubmit={handleSubmit}>
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <input
                  type="Email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="input-container">
                <input
                  type="number"
                  placeholder="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </div>
              <div className="input-container">
                <textarea
                  cols="40"
                  rows="5"
                  placeholder="Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              {/* this for chcking mail is sending successfully or not  */}
              <div className="display-container">
                <div
                  className={
                    checkemail ? "display_message" : "notdisplay_message"
                  }
                  id="display-success"
                >
                  Request has been sent successfully. Our team will get back to you soon.
                </div>

                <div
                  className={
                    emailNotSend ? "display_message" : "notdisplay_message"
                  }
                  id="display-error"
                >
                  Something went wrong! Please try again after some time.
                </div>
              </div>
              <div className="recaptcha">
                <ReCAPTCHA
                  ref = {captcha}
                  sitekey="6LcCEiYcAAAAAMSOVDa0nM2hTSS81y9i-hXK3sPB"
                  onChange={onChange}
                  className="captcha-box"
                />
              </div>
              {captchaValid === false &&
              <div className="captcha-error">
                Verify the Captcha
              </div>
            }
              <div className="send-message-btn">
                <button type="submit" className="send-btn">
                  Send Message
                </button>
              </div>
            </form>
          </div>
                
        </div>
        <div className="contact-details-container">
          <h5 className="details-heading">Contact us by phone or email</h5>
          <h2 className="contact-information">
            <a href="tel:+1647-616-2625" className="phone-number">
            +1 647-616-2625
            </a>
            <br />

            <span className="or">OR</span>
            <br />

            <a href="mailto:contact@revemax.ai" className="contact-email">
              contact@revemax.ai
            </a>
            <br />
          </h2>
          <div className="contact-icons">
            <div className="contact-icon">
              <a href="#" target="_blank">
                <i className="fa fa-twitter"></i>
              </a>
            </div>
            <div className="contact-icon">
              <a href="#" target="_blank">
                <i className="fa fa-facebook"></i>
              </a>
            </div>

            <div className="contact-icon">
              <a href="#" target="_blank">
                <i className="fa fa-linkedin"></i>
              </a>
            </div>
            <div className="contact-icon">
              <a href="#" target="_blank">
                <i className="fa fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;

//create a node mailer
