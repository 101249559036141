import Header from "./components/Header/Header";
import Navbar from "./components/Navbar/Navbar";
import News from "./components/News/News";
import Contact from "./components/Contact/Contact";
import ARMS from "./components/Product/ARMS/ARMS";
import ARX from "./components/Product/ARX/ARX";
import Consulting from "./components/Product/Consulting/Consulting";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";

import Prediction from "./components/Product/ARMS/Prediction/Prediction";

import { Route, Switch } from "react-router-dom";
import BannerCarousel from "./components/BannerCarousel/BannerCarousel";
import Analysis from "./components/Product/ARMS/Analysis/Analysis";
import Reporting from "./components/Product/ARMS/Reporting/Reporting";
import Upgrade from "./components/Product/ARX/Upgrade/Upgrade";
import Baggage from "./components/Product/ARX/Baggage/Baggage";
import Lounge from "./components/Product/ARX/Lounge/Lounge";
import Emptyseat from "./components/Product/ARX/Emptyseat.js/Emptyseat";
import Preferredseat from "./components/Product/ARX/Preferredseat/Preferredseat";
import Ancillary from "./components/Product/ARX/Ancillary/Ancillary";
import Aviation from "./components/Product/Consulting/Aviation/Aviation";

import MalaysiaAirlines from "./components/MalaysiaAirlines/MalaysiaAirlines";
import Pricing from "./components/Pricing/Pricing";
import Economics from "./components/Economics/Economics";
import Crisis from "./components/Crisis/Crisis";
import Uncategorized from "./components/Uncategorized/Uncategorized";
import Author from "./components/Author/Author";
import Admin from "./components/Admin/Admin";
import { useEffect } from "react";
import "./App.css";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);

    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    // return () => {
    //   preloader.className = "preloader preloader-deactivate";
    // };
  }, []);
  return (
    <div className="App">
      <div className="preloader">
        <div className="loader">
          <div className="loader-outter"></div>
          <div className="loader-inner"></div>
        </div>
      </div>
      <Header />
      <Navbar />
      <Switch>
        <Route exact path="/" >
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/ARMS" loading>
          <ARMS />
        </Route>
        <Route exact path="/prediction" component={Prediction} />
        <Route exact path="/analysis" component={Analysis} />
        <Route exact path="/reporting" component={Reporting} />
        {/* <Route exact path="/ARX">
          <ARX />
        </Route> */}
        <Route exact path="/upgrade" component={Upgrade} />
        <Route exact path="/baggage" component={Baggage} />
        <Route exact path="/lounge" component={Lounge} />
        <Route exact path="/emptyseat" component={Emptyseat} />
        <Route exact path="/preferredseat" component={Preferredseat} />
        <Route exact path="/ancillary" component={Ancillary} />
        <Route exact path="/Consulting">
          <Consulting />
        </Route>
        <Route exact path="/aviation" component={Aviation} />
        <Route exact path="/News" loading>
          <News />
        </Route>
        <Route exact path="/contact" loading>
          <Contact />
        </Route>
        <Route exact path="/malaysiaairlines" component={MalaysiaAirlines} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/economics" component={Economics} />
        <Route exact path="/crisis" component={Crisis} />
        <Route exact path="/uncategorized" component={Uncategorized} />
        <Route exact path="/author" component={Author} />
        <Route exact path="/admin" component={Admin} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;
