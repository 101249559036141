import React from "react";
import { Link } from "react-router-dom";
import "./Upgrade.css"
import upgrade from "../../images/upgrade.png";
import {useEffect} from "react";

function Upgrade() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])

  return (
    <div className="upgrade-container">
      <div
        className="upgrade-banner-area"
        style={{ background: `url(${upgrade})` }}
      >
        <div className="upgrade-content container">
          <div className="upgrade-links">
            <h2 className="upgrade-h2">Upgrade option</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Upgrade option</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="upgrade-content container">
        <div className="upgrade-heading">
        <i className="fa fa-arrow-up"></i>
          <h2 className="upgrade-title">Upgrade option</h2>
        </div>
        <p className="upgrade-para">
          Revemax is helping clients understand the complexities and bring in an
          intelligence to maximize their revenue in this competitive world, by
          working together to bring a solution which can put their organization
          at the competitive edge. Predictive analytics is often discussed in
          the context of big data. Business data at a company might include
          transaction data, sales results, customer complaints, marketing
          information and etc. Increasingly, businesses make data-driven
          decisions based on this valuable data of information. With all this
          data, tools are necessary to extract insights and trends. Machine
          learning techniques and Artificial Intelligence are used to find
          patterns in data and to build models that predict future outcomes.
        </p>
      </div>
    </div>
  );
}

export default Upgrade;