import React from "react";
import { Link } from "react-router-dom";
import "./Aviation.css";
import aviation from "../../images/aviation.jpg";
import chess from "../../images/chess.jpeg";
import {useEffect} from "react";

function Aviation() {
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="aviation-container">
      <div
        className="aviation-banner-area"
        style={{ background: `url(${aviation})` }}
      >
        <div className="aviation-content container">
          <div className="aviation-links">
            <h2 className="aviation-h2">Aviation Consulting</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>Aviation Consulting</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="aviation-content container">
        <div className="aviation-heading">
          <i className="fa fa-users"></i>
          <h2 className="aviation-title">Aviation Consulting</h2>
        </div>
        <p className="aviation-para">
          As Aviation is one of the key drivers of global economic development,
          we provide a multi-dimensional and interactive analysis tool to enable
          intelligent decisions for your airline.
        </p>
        <p className="aviation-para">
          The ReveMax consulting teams combine their expertise in all aspects of
          the airline industry, including strategy, pricing and revenue
          management, network planning, airline operations, sales and
          distribution, personalization, people and organization, digital and
          travel technologies, and sustainability. By working in hybrid teams,
          we view each airline holistically to ensure that every action delivers
          cascading benefits for the business as a whole.
        </p>
        <p className="aviation-para">
          Our work is always 100% customized to an Airline’s unique needs, and
          ReveMax’s airline consulting teams have a strong suite of proprietary
          tools to support our analysis and strategic approach.
        </p>
        <div className="aviation-img-container">
          <img src={chess} alt="" className="chess-img"/>
          <div className="aviation-text-container">
            <p className="aviation-para">
              • REVEMAX’s Airline Revenue Maximization Solution (ARMS) uses
              proprietary REVEMAX data and public sources to forecast market
              demand, predict airline industry trends, calculate profitability
              per route, and optimize for overall network profitability. The
              platform enables airlines to address network design at a time when
              historical models are completely unreliable for making the right
              big decisions on short notice. We have developed a
              state-of-the-art solution using artificial intelligence, machine
              learning, and advanced optimization to support global leaders in
              airline operations. This platform marks the first time a start-up
              has brought such a powerful tool to market to build a stronger,
              more capable airline organization.
            </p>
            <p className="aviation-para">
              • Our Subject Matter Experts (SMEs) have not only actively added
              to the creation of ARMS but are executives and hands-on experts
              from the Airline Industry. Our Team have contributed to the
              successes of some of the largest international carriers such as
              Qatar, Emirates, Etihad, Kenya Airways, Sri Lanka, Air Canada,
              Lufthansa to name a few and bring with them a wealth of knowledge
              and expertise to help guide you. While our platform is extremely
              intuitive and the UX has been honed to provide the ease of use not
              found in such complex software, having the expert guidance of our
              SME’s adds another layer of security and strategy that only comes
              with experience gained by decades of hands-on excellence.
            </p>
            <p className="aviation-para">
              Connect with us and see how we deliver on our promise of providing
              intelligent technology for intelligent airlines.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aviation;
