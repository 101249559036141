import React from "react";
import { Carousel } from "react-bootstrap";
import banner1 from "./images/banner-1.png";
import banner2 from "./images/banner-2.png";
import "./BannerCarousel.css";
import {useEffect} from "react";
import { Link } from "react-router-dom";

function BannerCarousel() {
    const style = {
        textTransform: "inherit",
        opacity: 1,
      };
      useEffect(() => {
        window.scrollTo(0, 0)
      },[])

  return (
    <div>
      
      <Carousel fade className="slider">
        <Carousel.Item interval={6500}>
          <img className="d-block w-100 banner-img" src={banner1} alt="Second slide" />
          <Carousel.Caption>
            <div className="main-banner-content text-left">
              <h1
                style={{ opacity: 1 }}
                className="animated fadeInUp heading"
                // data-aos="fade-up"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="2000"
                // data-aos-once="false"
              >
                ReveMax
              </h1>
              <p
                className="p animated fadeInDown"
                style={{ style }}
                // data-aos="fade-down"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-once="false"
              >
                BRINGING YOUR AIRLINES INTO THE DIGITAL WORLD
              </p>
              <div
                className="btn-box animated fadeInDown"
                style={{ opacity: 1 }}
                // data-aos="fade-down"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-once="false"
              >
                <Link
                  to="/contact"
                  className="reach-out-btn"
                >
                  Reach out to us
                </Link>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={6500}>
          <img className="d-block w-100 banner-img" src={banner2} alt="Second slide" />
          <Carousel.Caption>
            <div className="main-banner-content text-left">
              <h1
                style={{ opacity: 1 }}
                className="animated fadeInUp heading"
                // data-aos="fade-up"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-once="false"
              >
                ReveMax
              </h1>
              <p
                className="p animated fadeInDown"
                style={{ style }}
                // data-aos="fade-down"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-once="false"
              >
                A PREDICTIVE ANALYTICS AND DECISION MAKING SOLUTION FOR AIRLINES
              </p>
              <div
                className="btn-box animated fadeInDown"
                style={{ opacity: 1 }}
                // data-aos="fade-down"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-once="false"
              >
                <Link
                  to="/contact"
                  className="reach-out-btn"
                >
                  Reach out to us
                </Link>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    {/*
      <Carousel fade className="slider">
        <Carousel.Item interval={65000}>
          <img
            className="d-block w-100"
            src={banner1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={banner2}
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
    </Carousel>*/}
    </div>
  );
}

export default BannerCarousel;
