import { React, useEffect } from "react";
import { Link } from "react-router-dom";
import "./About.css";
import business from "./images/Business-technology-4.jpg";
import revemaxlogo from "./images/New-Project-16.png";
import person5 from "./images/5.jpg";
import person4 from "./images/4.jpg";
import person3 from "./images/3.jpg";
import person2 from "./images/2.jpg";
import person1 from "./images/1.jpg";
import { Carousel } from "react-bootstrap";
import client1 from "./images/client-1.mp4";
import client2 from "./images/client-2.mp4";
import client3 from "./images/client-3.mp4";
import ReactPlayer from "react-player";





function About() {
 
  
  useEffect(() => {
    window.scrollTo(0, 0);
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";
    


    // let myVideo1 = document.querySelector("#myVideo1");
    // let video1 = document.querySelector("#video1");

    // let myVideo2 = document.querySelector("#myVideo2");
    // let video2 = document.querySelector("#video2");

    // let myVideo3 = document.querySelector("#myVideo3");
    // let video3 = document.querySelector("#video3");
   

    //     document.querySelector("#carouselExampleControls").addEventListener("slide.bs.carousel", function (event) {
    //           myVideo1.remove();
    //           video1.appendChild(myVideo1);

    //           myVideo2.remove();
    //           video2.appendChild(myVideo2);

    //           myVideo3.remove();
    //           video3.appendChild(myVideo3);
    //     });
        
       



    return () => {
      preloader.className = "preloader";
    };

  }, []);

  return (
    <div className="about-container">
      <div
        className="about-banner-area"
        style={{ background: `url(${business})` }}
      >
        <div className="about-content container">
          <div className="about-links">
            <h2 className="about-h2">About Us</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i class="fa fa-angle-right"></i>
              </li>
              <li>About Us</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="about-area container">
        <div className="about-area-1">
          <div className="about-area-img">
            <img className="revemaxlogo" src={revemaxlogo} alt="" />
          </div>
          <div className="about-area-intro">
            <h2 className="about-heading">About Us</h2>
            <p className="para-1">
              We are a team of Global Executives who have a combined 100+ years
              of Airline/IT/Business experience.
            </p>
            <p className="para-2">
              We have a passion for the airline industry because we have lived
              it, giving us a vested interest in guiding them to profitability.
            </p>
          </div>
        </div>
        <div className="about-area-2">
          <div className="about-text-content">
            <p className="about-heading-para">
              <strong>4 SYNCHRONOUS MODULES</strong>
            </p>
            <p className="about-content-para">
              Bundled with the ability to Plug & Play, enabling scalability and
              faster trend capture.
            </p>
          </div>
          <div className="about-text-content">
            <p className="about-heading-para">
              <strong>ACCELERATED TIME TO MARKET</strong>
            </p>
            <p className="about-content-para">
              Up and running in 8 to 12 weeks with dedicated IT resource and buy
              in from Executive Teams.
            </p>
          </div>
          <div className="about-text-content">
            <p className="about-heading-para">
              <strong>ON PREMISE CLOUD IMPLEMENTATION</strong>
            </p>
            <p className="about-content-para">
              This is KEY and highest Priority, Security of Airline Data with
              Complete Audit Reporting.
            </p>
          </div>
          <div className="about-text-content">
            <p className="about-heading-para">
              <strong>FLEXIBLE, FLEXIBLE, FLEXIBLE</strong>
            </p>
            <p className="about-content-para">
              Flexible Payment Terms, Free Enhancements (Airline Related changes
              pertaining to their Strategy), No Hidden Fees or Maintenance Fees,
              we want to help you succeed
            </p>
          </div>
        </div>
      </div>
      <div className="why-container">
        <h1 className="why-heading">Why ReveMax?</h1>
        <div className="why-content-container container">
          <div className="why-content">
            <p className="why-para">
              <strong>Transparency</strong>
            </p>
            <p className="why-para-2">
              Within today’s environment there is often a lack of information
              transparency and coordination between the teams in Airline
              Commercial that has an impact on time to market which is
              eradicated by ReveMax.
            </p>
          </div>
          <div className="why-content">
            <p className="why-para">
              <strong>Standardization</strong>
            </p>
            <p className="why-para-2">
              Multiple tools exist to present, analyze, and report commercial
              performance, thereby creating a natural duplication of effort
              which leads to a high risk of subverting the commercial intent of
              the airline.
            </p>
          </div>
          <div className="why-content">
            <p className="why-para">
              <strong>Obsolete tools</strong>
            </p>
            <p className="why-para-2">
              Within today’s environment there is often a lack of information
              transparency and coordination between the teams in Airline
              Commercial that has an impact on time to market which is
              eradicated by ReveMax.
            </p>
          </div>
        </div>
      </div>

      <div className="team-container">
        <h1 className="team-heading">Meet Our Team</h1>
        <div className="team-card-container">
          <div className="team-card">
            <div className="card-img">
              <img src={person5} alt="" className="team-img" />
              <div className="social">
                <a
                  href="https://www.linkedin.com/in/nayeem-ekbal-74684155/"
                  target="_blank"
                >
                  <i className="fa fa-user-circle"></i>
                </a>{" "}
              </div>
            </div>
            <div className="card-detail">
              <h5 className="card-name">Nayeem Ekbal</h5>
              <p className="card-post">Chief Executive Officer</p>
            </div>
          </div>
        
          <div className="team-card">
            <div className="card-img">
              <img src={person3} alt="" className="team-img" />
              <div className="social">
                <a
                  href="#"
                  target="_blank"
                >
                  <i className="fa fa-user-circle"></i>
                </a>{" "}
              </div>
            </div>
            <div className="card-detail">
              <h5 className="card-name">Zubair Iqbal</h5>
              <p className="card-post">Chief Business Officer</p>
            </div>
          </div>
          <div className="team-card">
            <div className="card-img">
              <img src={person2} alt="" className="team-img" />
              <div className="social">
                <a
                  href="https://www.linkedin.com/in/john-rutherford-2a11872a/"
                  target="_blank"
                >
                  <i className="fa fa-user-circle"></i>
                </a>{" "}
              </div>
            </div>
            <div className="card-detail">
              <h5 className="card-name">John Rutherford</h5>
              <p className="card-post">Director</p>
            </div>
          </div>
          <div className="team-card">
            <div className="card-img">
              <img src={person1} alt="" className="team-img" />
              <div className="social">
                <a
                  href="https://www.linkedin.com/in/frank-bakker-78a53096/"
                  target="_blank"
                >
                  <i className="fa fa-user-circle"></i>
                </a>{" "}
              </div>
            </div>
            <div className="card-detail">
              <h5 className="card-name">Frank Bakker</h5>
              <p className="card-post">Consultant</p>
            </div>
          </div>
        </div>
      </div>
      <div className="client-container">
        <div className="client-review">
          <div className="client-heading-container">
            <h1 className="client-heading">OUR CLIENT REVIEWS</h1>
          </div>
          
          <div
            id="carouselExampleControls"
            className="carousel carousel-fade"
            data-bs-ride="carousel"
            data-bs-interval="false"
          >
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                data-bs-pause="false"
              >
                <div className="review-content">
                  <div id="video1">
                  {/* <iframe
                    width="500"
                    height="300"
                    src="https://www.youtube.com/embed/ZfVJevRZ3Ow?enablejsapi=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; playsinline"
                    allowfullscreen
                    className="clientVideo"
                    id="myVideo1"
                  ></iframe> */}
                  <ReactPlayer
                   url ="https://www.youtube.com/embed/ZfVJevRZ3Ow?enablejsapi=1"
                   width={500}
                   height={300}
                   controls="true"
                   playsinline />
                  </div>

                  <div className="review-caption">
                    <h2> Izham Ismail - Malaysia Aviation Group CEO Captain</h2>
                    <p>
                      “ARMS can provide a platform for me to monitor all
                      reporting dashboards from different companies at a
                      glance….”
                    </p>
                    <p>
                      “….with ARMS I can get relevant information at the click
                      of a button, at any level, at any time and this
                      information can assist me in making instant remedial
                      decisions or realign our strategy to fit the market.”
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                data-bs-pause="false"
              >
                <div className="review-content">
                
                  <div id="video2">
                  <iframe
                    width="500"
                    height="300"
                    src="https://www.youtube.com/embed/fesCdrBPG7M?enablejsapi=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; playsinline"
                    allowfullscreen
                    className="clientVideo"
                    id="myVideo2"
                  ></iframe>
                  </div>
                  <div className="review-caption">
                    <h2>
                      Joshua Law - GM, Strategic PMO Business Intelligence &
                      Product, MASKARGO, Malaysia Aviation Group
                    </h2>
                    <p>
                      “….ARMS comes into a very unique position, that it is
                      actually a one stop center with pre-analyzed data
                      transformed into logical decision making information, it
                      allows us to look at the trends as well as benchmarking
                      all the business activities…”
                    </p>
                    <p>
                      ”….this allows business users like myself and my team to
                      access the information instantly, improving the turnaround
                      time to go into the market with actionable plans”
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                data-bs-pause="false"
              >
                <div className="review-content">
               
                  <div id="video3">
                  <iframe
                    width="500"
                    height="300"
                    src="https://www.youtube.com/embed/quecTwxtuPM?enablejsapi=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; playsinline"
                    allowfullscreen
                    className="clientVideo"
                    id="myVideo3"
                  ></iframe>
                  </div>
                  <div className="review-caption">
                    <h2>
                      {" "}
                      Melvinder Kaur – Regional Sales Manager, Malaysia &
                      Brunei, Malaysia Airlines
                    </h2>
                    <p>
                      “…My scope of work involves a lot of data analysis,
                      reporting on our daily sales, this was previously done
                      manually. With ARMS I can now access the pre-analysed
                      information such as market share and execute dynamic
                      benchmarking, additionally we can personalize our fares
                      and offers to customers at a willing to pay rate using the
                      predictive forecasting features…”
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
              id = "prevMovie"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
              id= "nextMovie"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
