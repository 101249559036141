import React from "react";
import { Link } from "react-router-dom";
import "./Reporting.css";
import reporting from "../../images/reporting.jpg";
import {useEffect} from "react";

function Prediction() {
  useEffect(() => {
    window.scrollTo(0, 0)
    const preloader = document.querySelector(".preloader");
    preloader.className = "preloader preloader-deactivate";

    return () => {
      preloader.className = "preloader";
    };
  },[])
  return (
    <div className="reporting-container">
      <div
        className="reporting-banner-area"
        style={{ background: `url(${reporting})` }}
      >
        <div className="reporting-content container">
          <div className="reporting-links">
            <h2 className="reporting-h2">Reporting</h2>
            <ul>
              <li>
                <Link to="/" className="home-link">
                  Home
                </Link>
              </li>
              <li>
                <i className="fa fa-angle-right"></i>
              </li>
              <li>Reporting</li>
            </ul>
          </div>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </div>
      </div>

      <div className="reporting-content container">
        <div className="reporting-heading">
          <i class="fa fa-pie-chart"></i>
          <h2 className="reporting-title">Reporting</h2>
        </div>
        <p className="reporting-para">
          Business reports document the progress of your business and the data
          collected serves several important purposes. It guides strategic
          decision making, helping business leaders to formulate budget and
          planning activities for the ensuing year using the report data to back
          choices and provide justification for each decision, Monitoring and
          reporting over time will not only highlight problems but can also
          identify opportunities for growth or expansion. Reports also work as a
          means of recording previous activities and help to define future
          growth opportunities by identifying already proven successes or what
          else could be done moving forward.
        </p>
      </div>
    </div>
  );
}

export default Prediction;
